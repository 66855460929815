import React from 'react';
import { InfoCard } from '@latitude/info-card';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import { Vertical } from '@latitude/spacing';

const CorporateResponsibilitySupportingCustomers = () => {
  return (
    <InfoCard bodyBGColor="#E8F4FF" heading="Supporting Vulnerable Customers">
      <Vertical spacing="24px">
        <Box>
          <Text align="center">
            As an essential finance provider to over 2m customers across
            Australia and New Zealand it&rsquo;s our job to support customers
            through the difficult times, including the COVID-19 pandemic.
            We&rsquo;ve implemented a range of measures to support our customers
            and make sure we remain safe, trusted and&nbsp;responsible.
          </Text>
          <Link
            button="tertiary"
            href="https://assets.latitudefinancial.com/corporate-responsibility/latitude-supporting-customers-report-2020.pdf"
            trackId="corporate-responsibility-supporting-customers"
            width="200px"
            css={`
              margin: 20px auto 0;
            `}
          >
            Read more
          </Link>
        </Box>
      </Vertical>
    </InfoCard>
  );
};

export default CorporateResponsibilitySupportingCustomers;
